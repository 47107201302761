<template>
  <v-main>
    <v-row
      no-gutters
      class="ma-0"
      v-resize="onResize"
      :style="'height: ' + windowSize.y + 'px;'"
    >
      <v-col
        cols="6"
        class="d-flex justify-center align-center blue white--text"
      >
        <div>
          <p class="text-h2">存证服务平台</p>
          <br />
          <p class="text-h6">上海领声电子商务有限公司</p>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex justify-center align-center white">
        <v-card flat width="512">
          <v-card-text>
            <v-text-field outlined label="用户名" v-model="loginForm.username">
            </v-text-field>
            <v-text-field
              outlined
              type="password"
              label="密码"
              v-model="loginForm.password"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn block @click="handleLogin">登陆</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    windowSize: { x: 0, y: 0 },
    loginForm: { username: "", password: "" },
    loading: false,
  }),
  mounted() {
    this.onResize();
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    handleLogin() {
      if (this.loginForm.username.trim() || this.loginForm.password.trim()) {
        this.loading = true;
        this.$store
          .dispatch("user/login", this.loginForm)
          .then(() => {
            this.$router.push({ path: this.redirect || "/" });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
